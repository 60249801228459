<template>
  <div class="card section">
    <div>
      <b-field>
        <p class="subtitle">{{ localExam.title }}</p>
      </b-field>
      <b-field>
        <p>{{ localExam.description }}</p>
      </b-field>
      <hr />
    </div>
    <div v-for="(question, index) in localExam.questions" :key="question.id">
      <UserExamPreviewQuestionMentor
        :indexQ="index"
        :question="question"
        :showCorrectAnswer="showCorrectAnswer"
        :gradute="true"
        @changePoints="setPoints"
      ></UserExamPreviewQuestionMentor>
    </div>
    <div class="columns m-4">
      <div class="column is-half" v-if="localExam.gates">
        <p class="subtitle">{{t('exam.gates')}}</p>
        <div v-for="(grade, index) in localExam.gates" :key="index">
          <p v-if="index < localExam.gates.length - 1">
            {{t('exam.gatesFrom')}} {{ grade.requiredPoints }}% {{t('exam.gatesTo')}}
            {{ localExam.gates[index + 1].requiredPoints }}% - {{t('exam.gradeLower')}}
            {{ grade.grade }}
          </p>
          <p v-else>
            {{t('exam.gatesFrom')}} {{ grade.requiredPoints }}% {{t('exam.gatesTo')}} 100% - {{t('exam.gradeLower')}} {{ grade.grade }}
          </p>
        </div>
      </div>
      <div class="column is-half">
        <p>
          {{t('grade.infoPoint')}}: {{ pointQuestions }}/
          {{ maxPointQuestions }}
        </p>
        <p>{{t('grade.infoPercent')}}: {{ percentGrade }}</p>
        <p v-if="localExam.type === 0" class="subtitle">
          {{t('exam.grade')}}: {{ currentGrade }}
        </p>
        <div class="buttons">
          <b-button type="is-danger" icon-left="close" @click="close"
            >{{t('common.cancel')}}</b-button
          >
          <b-button type="is-success" icon-left="check" @click="setGrade"
            >{{t('common.saveChanges')}}</b-button
          >
        </div>
      </div>
    </div>
    <b-loading v-model="isLoading" :can-cancel="true"></b-loading>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import UserExamPreviewQuestionMentor from "./UserExamPreviewQuestionMentor";
import { Action } from "@/store/config_actions";

export default {
  name: "UserExamPreview",
  mixins: [CommonHelpers],
  components: {
    UserExamPreviewQuestionMentor,
  },

  props: {
    exam: { type: Object, required: true },
    showCorrectAnswer: { type: Boolean, required: false },
  },

  data: function () {
    return {
      localExam: {},
      isLoading: false,
      refreshTrigger: 0,
      currentPoints: 0,
      maxPoints: 0,
      examGates: [
        {
          grade: 2.0,
          requiredPoints: 0,
        },
        {
          grade: 2.5,
          requiredPoints: 30,
        },
        {
          grade: 3.0,
          requiredPoints: 40,
        },
        {
          grade: 3.5,
          requiredPoints: 50,
        },
        {
          grade: 4.0,
          requiredPoints: 60,
        },
        {
          grade: 4.5,
          requiredPoints: 70,
        },
        {
          grade: 5.0,
          requiredPoints: 80,
        },
      ],
    };
  },

  mounted() {
    this.localExam = JSON.parse(JSON.stringify(this.exam));
    this.refreshTrigger++;
  },

  watch: {
    refreshTrigger() {
      if (this.localExam.questions) {
        this.currentPoints = 0;
        this.localExam.questions.forEach((question) => {
          this.currentPoints += question.points;
        });
      }
    },
  },

  computed: {
    percentGrade() {
      if (this.maxPointQuestions > 0) {
        return Math.ceil((this.pointQuestions / this.maxPointQuestions) * 100);
      } else {
        return 0;
      }
    },

    pointQuestions() {
      return this.currentPoints;
    },

    maxPointQuestions() {
      let points = 0;
      if (this.localExam.questions) {
        this.localExam.questions.forEach((question) => {
          points += question.maxPoints;
        });
      }
      return points;
    },

    currentGrade() {
      if (this.localExam.gates && this.localExam.gates.length > 0) {
        for (let index = this.localExam.gates.length - 1; index > 0; index--) {
          const minGate = this.localExam.gates[index];

          if (this.percentGrade >= minGate.requiredPoints) {
            return minGate.grade;
          }
        }

        return 2.0;
      } else {
        return 0;
      }
    },
  },

  methods: {
    setPoints(questionPoints) {
      this.refreshTrigger++;
      let question = this.localExam.questions.find(
        (q) => q.id === questionPoints.id
      );
      if (question) {
        let indexQuestion = this.localExam.questions.indexOf(question);
        this.localExam.questions[indexQuestion].points = questionPoints.points;
      }
    },

    setGrade() {
      if (this.localExam.gates && this.localExam.type === 0) {
        this.localExam.grade = this.currentGrade;
        this.localExam.isGraded = true;
      }

      this.$store
        .dispatch(Action.USEREXAM_UPDATE, this.localExam)
        .then(() => {
          this.mSuccessSnack(this.t('common.changesSaved'));
          this.$emit("close");
          this.$emit("saveChange");
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
</style>