<template>
  <div>
    <b-table :data="exams">
      <b-table-column field="student" :label="t('common.student')" v-slot="props">
        <DetailUser v-if="props.row.userId" :id="props.row.userId">
          <template v-slot:default="slotProps">
            {{ slotProps.user.studentIndex }}
            {{ slotProps.user.firstName }}
            {{ slotProps.user.lastName }}
          </template>
        </DetailUser>
      </b-table-column>
      <b-table-column
        field="time"
        :label="t('exam.userTime')"
        sortable
        v-slot="props"
      >{{ mformattedTimeLeft(props.row.time) }}</b-table-column>
      <b-table-column field="tags" :label="t('forms.tags')" v-slot="props">
        <span v-for="tag in props.row.tags" :key="tag" class="tag mr-2">{{ tag }}</span>
      </b-table-column>
      <b-table-column field="grade" :label="t('exam.grade')" v-slot="props">{{ props.row.grade }}</b-table-column>
      <b-table-column field="state" :label="t('common.state')" v-slot="props">
        <b-tooltip v-if="props.row.isFinished" :label="t('common.sended')" position="is-right">
          <b-icon icon="check"></b-icon>
        </b-tooltip>
        <b-tooltip v-else :label="t('common.notSended')" position="is-right">
          <b-icon icon="clock"></b-icon>
        </b-tooltip>
      </b-table-column>
      <b-table-column field="option" v-slot="props" :label="t('common.options')">
        <b-tooltip :label="t('classroom.previewExam')">
          <b-button @click="showExam(props.row.id)" icon-left="eye" size="is-small"></b-button>
        </b-tooltip>
      </b-table-column>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="package-variant" size="is-large"></b-icon>
            </p>
            <p>{{t('common.noData')}}</p>
          </div>
        </section>
      </template>
    </b-table>
    <b-modal v-model="isModalExamActive">
      <div class="modal-content exam-modal">
        <UserExamPreviewMentor :exam="examToShow" :showCorrectAnswer="true" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import UserExamPreviewMentor from "./userexamPreview/UserExamPreviewMentor";
import DetailUser from "@/components/username/DetailUser";
import { Action } from "../../store/config_actions";
import FormattedHelpers from "@/mixins/formatted";

export default {
  name: "MentorDetailTables",
  mixins: [CommonHelpers, FormattedHelpers],
  components: {
    UserExamPreviewMentor,
    DetailUser,
  },

  props: {
    classId: { type: String, required: true },
    onlyFinished: { type: Boolean, required: false },
  },

  data: function () {
    return {
      exams: [],
      isModalExamActive: false,
      examToShow: null,
    };
  },

  mounted() {
    this.getUserExams();
  },

  watch: {},

  computed: {},

  methods: {
    showExam(id) {
      this.examToShow = this.exams.find((ex) => ex.id == id);
      //if (this.examToShow.type) {
      if (this.examToShow.type === 0) {
        this.isModalExamActive = !this.isModalExamActive;
      } else if (this.examToShow.type === 1 || this.examToShow.type === 2) {
        this.$router.push({
          name: "examimagementor",
          params: { idUserExam: this.examToShow.id },
        });
      } else {
        this.mDangerSnack(this.t('exam.errorType'));
      }
      // } else {
      //   this.mDangerSnack("Brak typu egzaminu");
      // }
    },

    getUserExams() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.USEREXAM_GET_BY_CLASSID, this.classId)
        .then((payload) => {
          if (this.onlyFinished) {
            this.exams = payload.filter((ex) => ex.isFinished);
          } else {
            this.exams = payload;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.exam-modal {
  background-color: white;
}
</style>
