<template>
  <span>
    <slot v-bind:user="user"></slot>
  </span>
</template>
   

<script>
import CommonHelpers from "@/mixins/commons";
import { Action } from "../../store/config_actions";

export default {
  name: "Detailuser",
  mixins: [CommonHelpers],
  components: {},

  props: { id: { type: String, required: true } },

  data: function () {
    return {
      user: {},
    };
  },

  mounted() {
    if (this.id) {
      this.getUser(this.id);
    }
  },

  watch: {},

  computed: {},

  methods: {
    getUser(id) {
      this.isLoading = true;
      this.$store
        .dispatch(Action.AUTH_GET_USER, id)
        .then((payload) => {
          this.user = payload;
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.exam-modal {
  background-color: white;
}
</style>
