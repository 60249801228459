<template>
  <div class="home">
    <MentorExams />
  </div>
</template>

<script>
// @ is an alias to /src
import MentorExams from '@/components/userexam/MentorExams'

export default {
  name: 'ExamsUserMentorView',
  components: {
    MentorExams
  }
}
</script>