<template>
  <div>
    <b-table :data="exams">
      <b-table-column
        field="studentName"
        searchable
        sortable
        :label="t('common.student')"
        v-slot="props"
      >{{ props.row.studentName }}</b-table-column>
      <b-table-column
        field="time"
        :label="t('exam.userTime')"
        sortable
        v-slot="props"
      >{{ mformattedTimeLeft(props.row.time) }}</b-table-column>
      <b-table-column field="tags" :label="t('forms.tags')" v-slot="props">
        <span v-for="tag in props.row.tags" :key="tag" class="tag mr-2">{{ tag }}</span>
      </b-table-column>
      <b-table-column field="grade" :label="t('exam.grade')" v-slot="props">
        <template v-if="props.row.isGraded">{{ props.row.grade }}</template>
      </b-table-column>
      <b-table-column field="option" v-slot="props" :label="t('common.options')">
        <b-tooltip :label="t('grade.preview')">
          <b-button @click="showExam(props.row.id)" icon-left="star" size="is-small">{{t('grade.add')}}</b-button>
        </b-tooltip>
      </b-table-column>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="package-variant" size="is-large"></b-icon>
            </p>
            <p>{{t('common.noData')}}</p>
          </div>
        </section>
      </template>
    </b-table>
    <b-modal full-screen v-model="isModalExamActive" :can-cancel="true">
      <!-- <div class="modal-content exam-modal"> -->
      <template #default="props">
        <UserExamGrade
          :exam="examToShow"
          :showCorrectAnswer="true"
          @close="props.close"
          :gradute="true"
          @updateExam="refreshTrigger++"
        />
      </template>
      <!-- </div> -->
    </b-modal>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import UserExamGrade from "./userexamPreview/UserExamSetGrade";
import { Action } from "../../store/config_actions";
import FormattedHelpers from "@/mixins/formatted";

export default {
  name: "MentorGradesTables",
  mixins: [CommonHelpers, FormattedHelpers],
  components: {
    UserExamGrade
  },

  props: {
    classId: { type: String, required: true },
  },

  data: function () {
    return {
      exams: [],
      isModalExamActive: false,
      examToShow: null,
      refreshTrigger: 0,
    };
  },

  mounted() {
    this.getUserExams();
  },

  watch: {
    refreshTrigger() {
      this.getUserExams();
    },
  },

  computed: {},

  methods: {
    showExam(id) {
      this.examToShow = this.exams.find((ex) => ex.id == id);
      // if (this.examToShow.type === null) {
      if (this.examToShow.type === 0) {
        this.isModalExamActive = !this.isModalExamActive;
      } else if (this.examToShow.type === 1 || this.examToShow.type === 2) {
        this.$router.push({
          name: "examimagementor",
          params: { idUserExam: this.examToShow.id },
        });
      } else {
        this.mDangerSnack(this.t('exam.errorType'));
      }
      // } else {
      //   this.mDangerSnack("Brak typu egzaminu");
      // }
    },

    getUserExams() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.USEREXAM_GET_BY_CLASSID, this.classId)
        .then((payload) => {
          // this.exams = payload.filter((ex) => ex.isFinished && !ex.isGraded);
          this.exams = payload;
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    saveGrade(exam) {
      exam.isGraded = true;
      this.$store
        .dispatch(Action.USEREXAM_UPDATE, exam)
        .then(() => {
          this.mSuccessSnack(this.t('common.changesSaved'));
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.exam-modal {
  background-color: white;
}
</style>
