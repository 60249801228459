
export default {
    data: function () {
        return {
        }
    },

    methods: {
        mformattedTimeLeft(time) {
            const timeLeft = time;

            const timeMinutes = Math.floor(timeLeft / 60);
            const hours = Math.floor(timeMinutes / 60);
            let minutes = timeMinutes % 60;
            let seconds = timeLeft % 60;

            if (minutes < 10) {
                minutes = `0${minutes}`;
            }

            if (seconds < 10) {
                seconds = `0${seconds}`;
            }

            return `${hours}:${minutes}:${seconds}`;
        },

        mformattedTimeMinSec(timeMinutes) {
            const timeLeft = this.timeLeft;
            const minutes= Math.floor(timeMinutes / 60);
            
            let seconds = timeLeft % 60;

            if (seconds < 10) {
                seconds = `0${seconds}`;
            }

            return `${minutes}:${seconds}`;
        }
    }
}