<template>
  <div>
    <UserFeedback
      :target="t('componentNames.mentorExamsList')"
      class="end mr-4 mt-1"
      :position="'is-left'"
    ></UserFeedback>
    <b-field grouped group-multiline>
      <b-field>
        <b-button :loading="isLoading" @click="getMyClassrooms" icon-left="refresh">{{t('common.refresh')}}</b-button>
      </b-field>
      <b-field :label="t('common.from')" label-position="on-border">
        <b-datepicker v-model="date.from" icon="calendar-today"></b-datepicker>
      </b-field>
      <b-field :label="t('common.to')" label-position="on-border">
        <b-datepicker v-model="date.to" icon="calendar-today" :min-date="dateFrom"></b-datepicker>
      </b-field>
    </b-field>
    <b-table
      narrowed
      :loading="isLoading"
      :data="filteredClassrooms"
      striped
      hoverable
      detailed
      detail-key="id"
    >
      <b-table-column
        field="name"
        :label="t('classroom.header')"
        searchable
        sortable
        v-slot="props"
      >{{ props.row.name }}</b-table-column>
      <b-table-column
        field="exam"
        :label="t('exam.header')"
        searchable
        sortable
        v-slot="props"
      >{{ props.row.exam.title }}</b-table-column>
      <b-table-column field="type" :label="t('classroom.type')" v-slot="props">
        <span>
          <b-icon :icon="mGetClassroomTypeIcon(props.row.type)"></b-icon>
        </span>
        {{ mGetClassroomTypeName(props.row.type) }}
      </b-table-column>
      <b-table-column
        field="starts"
        :label="t('exam.start')"
        searchable
        sortable
        v-slot="props"
      >{{ mDateTime(props.row.starts) }}</b-table-column>
      <b-table-column
        field="ends"
        :label="t('exam.end')"
        searchable
        sortable
        v-slot="props"
      >{{ mDateTime(props.row.ends) }}</b-table-column>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="package-variant" size="is-large"></b-icon>
            </p>
            <p>{{t('common.noData')}}</p>
          </div>
        </section>
      </template>
      <template slot="detail" slot-scope="props">
        <template v-if="isGrades">
          <MentorGradesTables :classId="props.row.id"></MentorGradesTables>
        </template>
        <template v-else>
          <MentorDetailTables :classId="props.row.id" :onlyFinished="onlyFinished"></MentorDetailTables>
        </template>
      </template>
    </b-table>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import TypesHelpers from "@/mixins/types";
import { Action } from "@/store/config_actions";
import MentorDetailTables from "./MentorDetailTables";
import MentorGradesTables from "./MentorGradesTables";
import UserFeedback from '@/components/feedbacks/UserFeedback'

export default {
  name: "MentorUserExamList",
  mixins: [CommonHelpers, TypesHelpers],
  components: {
    MentorDetailTables,
    MentorGradesTables,
    UserFeedback
  },

  props: {
    isGrades: { type: Boolean, required: true },
    //we need type: 0-zaplanowane, 1 - aktualne, 2 - zakończone, nieustawione - wszystkie
    timeType: { type: Number, required: false },
  },

  data: function () {
    return {
      classrooms: [],
      isLoading: false,
      isModalExamActive: false,
      date: {
        from: null,
        to: null,
        isFinished: false,
        isGraded: false
      }
    };
  },

  mounted() {
    //this.getClassrooms();
    this.date.to = new Date(this.mNowPlusDays(30));
    this.date.from = new Date(this.mNowPlusDays(-10));
  },

  watch: {
    dateFrom() {
      if (this.date.to && this.date.from) {
        this.getMyClassrooms();
      }
    },

    dateTo() {
      if (this.date.to && this.date.from) {
        this.getMyClassrooms();
      }
    },
  },

  computed: {
    filteredClassrooms() {
      if (this.classrooms) {
        let filtered = this.classrooms;

        if (this.timeType === 0) {
          filtered = filtered.filter((ex) => !this.mAfterNow(ex.starts));
        } else if (this.timeType === 1) {
          filtered = filtered.filter(
            (ex) =>
              (this.mBeforeNow(ex.ends) && this.mAfterNow(ex.starts)) === true
          );
        } else if (this.timeType === 2) {
          filtered = filtered.filter((ex) => !this.mBeforeNow(ex.ends));
        } else {
          //do nothing get all
        }

        return filtered;
      } else return [];
    },

    onlyFinished() {
      if (this.timeType === 0 || this.timeType === 1) {
        return true;
      } else if (this.timeType === 2 || this.timeType === 3) {
        return false;
      } else {
        return true;
      }
    },

    dateFrom() {
      return new Date(this.date.from);
    },

    dateTo() {
      return new Date(this.date.to);
    }
  },

  methods: {

    getMyClassrooms() {
      this.date.isGraded = this.isGrades;
      this.date.isFinished = this.onlyFinished;
      if (this.isGrades) {
        this.date.isFinished = true;
        this.date.isGraded = true;
      }
      this.isLoading = true;
      this.$store
        .dispatch(Action.CLASSROOMS_FROM_USEREXAMS, this.date)
        .then((payload) => {
          this.classrooms = payload;
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    getClassrooms() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.CLASSROOM_GET_ALL)
        .then((payload) => {
          this.classrooms = payload.filter((cl) => cl.type !== 0);
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.exam-modal {
  background-color: white;
}
div.end {
  float: right;
}
</style>
