<template>
  <div class="card mt-4 ml-5 mr-5" style="min-height: 70vh;">
    <b-tabs :animated="false">
        <b-tab-item :label="t('componentNames.examsActive')" icon="file-multiple">
          <MentorUserExamList  :isGrades="false" :timeType="1"/>
        </b-tab-item>
        <b-tab-item :label="t('componentNames.examsEnded')" icon="file-lock">
          <MentorUserExamList  :isGrades="false" :timeType="2"/>
        </b-tab-item>
        <b-tab-item :label="t('componentNames.examsForGraduate')" icon="file-star">
          <MentorUserExamList  :isGrades="true"/>
        </b-tab-item>
        <b-tab-item :label="t('componentNames.examsOngoing')" icon="file-clock">
          <MentorUserExamList  :isGrades="false" :timeType="0"/>
        </b-tab-item>        
        <!-- <b-tab-item label="Egzaminy archiwalne" icon="file-lock">
          <MentorUserExamList  :isGrades="false" :timeType="3"/>
        </b-tab-item> -->
    </b-tabs>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import MentorUserExamList from "./MentorUserExamList";

export default {
  name: "MentorExams",
  mixins: [CommonHelpers],
  components: {
      MentorUserExamList,
  },

  props: {},

  data: function () {
    return {
    };
  },

  mounted() {},

  watch: {},

  computed: {},

  methods: {
  },
};
</script>

<style scoped>
.card {
  overflow: visible !important;
}
</style>
